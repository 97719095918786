@content13: content13;
.@{content13}-wrapper {
  min-height: 180px;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
  &.home-page-wrapper {
      .title-wrapper {
      margin-bottom: 0;
    }
  }
  .title-content {
    font-size: 18px;
    line-height: 32px;
  }
}

@media screen and (max-width: 767px) {
  .@{content13}-wrapper {
    padding-bottom: 0;
  }
}
