@teams1: teams1;
.@{teams1}-wrapper {
  min-height: 446px;
  overflow: hidden;
  .@{teams1} {
    overflow: hidden;
    height: 100%;
    padding: 64px 24px;
    > .title-wrapper {
      margin: 0 auto 48px;
    }
    .block-wrapper {
      position: relative;
      height: 100%;
      overflow: hidden;
      padding: 20px 0;
      .block {
        display: inline-block;
        text-align: center;
        margin-bottom: 48px;
        &.queue-anim-leaving {
          position: relative !important;
        }
      }
    }
    &-image, &-title, &-job, &-content {
      width: 200px;
      margin: auto;
      line-height: 1.5;
      border-radius: 50%;
    }
    &-content {
      span {
        p {
          font-size: 16px;
        }
      }
    }
    &-image {
      color: #404040;
      img {
        width: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    &-title {
      font-size: 24px;
      margin: 24px auto 8px;
    }
    &-job {
      margin: 8px auto;
    }
    &-job, &-content {
      font-size: 16px;
      color: #919191;
    }
  }
}

@media screen and (max-width: 767px) {
  .@{teams1}-wrapper {
    min-height: 1440px;
  }
}
