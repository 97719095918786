#Nav2_0 .kw8ovdhy60f-editor_css {
  width: 100%;
  max-width: max;
}

#Nav2_0 .home-page {
  display: inline;
}

#Nav2_0 .kw8ovdhy60f-editor_css>.header2-menu>.kw8p05mvxgk-editor_css {
  right: 20px;
}

@media screen and (max-width: 600px) {
  .header2-menu {
    display: none;
  }
}

#Banner0_1 .banner0-text-wrapper>.kw8p6r81geq-editor_css {
  display: inline;
}

#Nav2_0.kw8ov9mrez-editor_css {
  position: fixed;
}

#Content13_0 .title-wrapper>.kw8pf8i3l3-editor_css {
  display: inline-block;
}

#Feature0_0 .kw8puqvo4bo-editor_css {
  display: inline;
}

#Content13_2 .title-wrapper>.kw8u39ikdr6-editor_css {
  justify-content: space-around;
}

#Feature0_1 div>.ant-row>.kw8u6ny9a7d-editor_css {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 50px;
}

#Feature0_1 div>.ant-row>.kw8u6rj1xwe-editor_css {
  min-height: 50px;
}

#Feature0_1 div>.ant-row>.kw8u6wdfqj8-editor_css {
  min-height: 50px;
}

#Feature0_1 div>.ant-row>.kw8u6syfwg-editor_css {
  min-height: 50px;
}